<template>
  <div class="card my-3 p-3 text-center">
    <h4 class="font-weight-bold">{{ $t("quote-card.title") }}</h4>
    <h3>{{ `${year} ${maker} ${model}` }}</h3>
    <h2 class="font-weight-bold text-dark-blue">
      {{
        quoteRange.min
          ? `${$t("currency.symbol")}${Intl.NumberFormat(VUE_APP_INTL).format(
              quoteRange.min
            )}`
          : null
      }}
      {{
        quoteRange.max
          ? `- ${$t("currency.symbol")}${Intl.NumberFormat(VUE_APP_INTL).format(
              quoteRange.max
            )}`
          : null
      }}
    </h2>
    <small class="text-gray">{{ $t("quote-card.notation") }}</small>
  </div>
</template>
<script>
import { mapActions } from "vuex";

export default {
  name: "QuoteCard",
  props: ["email", "phone", "model", "maker", "year", "version", "mileage"],
  data() {
    return { VUE_APP_INTL: process.env.VUE_APP_INTL };
  },
  mounted() {
    this.CREATE_LEAD({
      maker: this.maker,
      year: this.year,
      model: this.model,
      version: this.version,
      mileage: this.mileage,
      minPrice: this.quoteRange.min,
      maxPrice: this.quoteRange.max,
      email: this.email,
      phone: this.phone,
      plate: this.plate,
    });
  },
  methods: {
    ...mapActions({
      CREATE_LEAD: "quote/CREATE_LEAD",
    }),
  },
  computed: {
    plate: {
      get() {
        return this.$store.state.form.plate;
      },
    },
    quoteRange: {
      get() {
        return this.$store.state.quote.quoteRange;
      },
    },
  },
};
</script>
