<template>
  <main>
    <section class="floating-card no-content bg-dark-blue">
      <QuoteCard
        :email="email"
        :phone="phone"
        :model="model"
        :maker="maker"
        :year="year"
        :version="version"
        :mileage="milage"
      />
    </section>
    <section class="py-5">
      <!-- <h4 class="font-weight-bold text-blue d-block text-center mb-4">
        {{ $t("schedule.title") }}
      </h4> -->
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-12 col-md-6">
            <div class="mb-4">
              <ul class="blue-arrow-list mb-2">
                <li>
                  <span class="d-block font-weight-bold">{{
                    $t("schedule.self-inspection.title")
                  }}</span>
                  <small>{{ $t("schedule.self-inspection.tip") }}</small>
                </li>
              </ul>

              <router-link
                to="/self-inspection"
                class="text-decoration-none btn btn-primary d-block mb-2 btn-lg"
              >
                <span class="d-block text-uppercase" @click="goSelfInspection">
                  {{ $t("schedule.self-inspection.btn") }}
                </span>
              </router-link>
            </div>
            <div class="mb-0">
              <ul class="blue-arrow-list mb-2">
                <li>
                  <span class="d-block font-weight-bold">{{
                    $t("schedule.scheduled-inspection.title")
                  }}</span>
                  <small>{{ $t("schedule.scheduled-inspection.tip") }}</small>
                </li>
              </ul>
              <a
                :href="`https://www.olxautos.${VUE_APP_COUNTRY.toLowerCase()}/cotizacion/2/?email=${email}&phone=${phone}&make=${maker}&model=${model}&trim=${version}&year=${year}&mileage=${milage}&skipself=true`"
                target="_self"
                class="btn btn-secondary d-block mb-2 btn-lg"
                @click="goAway"
              >
                <span class="d-block text-uppercase">
                  {{ $t("schedule.scheduled-inspection.btn") }}
                </span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="bg-light-gray py-5">
      <div class="container">
        <div class="row">
          <div class="col">
            <h4 class="font-weight-bold text-center d-block mb-4">
              {{ $t("self-inspection-faq.title") }}
            </h4>
            <ul class="blue-tick-list">
              <li
                class="mb-2"
                v-for="(item, i) of $t('self-inspection-faq.list')"
                :key="item[i]"
              >
                <span>
                  {{ item }}
                </span>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </section>
    <!-- <section class="py-3">
      <div class="container">
        <div class="row">
          <div class="col-12">
            <hr class="bg-primary w-100 d-block" />
            <h4 class="d-block text-center">text</h4>
            <button
              class="btn btn-info d-flex align-items-center justify-content-center w-100 btn-lg"
            >
              <IconLocation class="mr-1" /> text
            </button>
            <div class="d-flex align-items-center justify-content-center w-100">
              <hr class="bg-primary flex-grow-1" />
              <p class="my-0 mx-2">text</p>
              <hr class="bg-primary flex-grow-1" />
            </div>
            <form>
              <input
                type="text"
                class="form-control mb-2"
                placeholder="Selecciona tu ciudad"
              />
              <input
                type="text"
                class="form-control mb-2"
                placeholder="Selecciona un punto"
              />
              <input
                type="text"
                class="form-control mb-2"
                placeholder="Selecciona tu ciudad"
                disabled
              />
              <router-link to="/step-2-presencial-cl">
                <button class="btn btn-primary btn-lg w-100">button</button>
              </router-link>
            </form>
          </div>
        </div>
      </div>
    </section> -->
  </main>
</template>

<script>
import IconLocation from "../components/IconLocation";
import QuoteCard from "../components/QuoteCard";

export default {
  components: {
    // IconLocation,
    QuoteCard,
  },
  data() {
    return { VUE_APP_COUNTRY: process.env.VUE_APP_COUNTRY };
  },
  mounted() {
    window.exponea.track("quote", {
      quote_email: this.email,
      quote_phone: this.phone,
      quote_make: this.maker,
      quote_model: this.model,
      quote_trim: this.version,
      quote_year: this.year,
      quote_mileage: this.milage,
      quote_PriceMin: this.quoteRange.min,
      quote_PriceMax: this.quoteRange.max,
    });

    console.log(
      this.maker,
      this.model,
      this.version,
      this.year,
      this.milage,
      this.quoteRange
    );

    window.dataLayer.push({
      event: "gaEvent",
      eventCategory: "form",
      eventAction: "interact",
      eventLabel: "gotQuote",
      cdMake: this.maker,
      cdModel: this.model,
      cdTrim: this.version,
      cdYear: this.year,
      cdMileage: this.milage,
      cdPrice: this.quoteRange.min,
    });
  },
  methods: {
    goSelfInspection() {
      window.dataLayer.push({
        event: "gaEvent",
        eventCategory: "evo-evadis",
        eventAction: "click",
        eventLabel: "Link a Inspección a Distancia",
      });
    },
    goAway() {
      window.dataLayer.push({
        event: "gaEvent",
        eventCategory: "evo-evadis",
        eventAction: "click",
        eventLabel: "Link a Inspección Presencial",
      });
    },
  },
  computed: {
    quoteRange: {
      get() {
        return this.$store.state.quote.quoteRange;
      },
    },
    newForm: {
      get() {
        return this.$store.state.quote.newForm;
      },
    },
    maker: {
      get() {
        const fieldIndex = this.newForm.findIndex(
          (field) => field.name == "maker"
        );

        return this.$store.state.quote.newForm[fieldIndex].value;
      },
    },
    year: {
      get() {
        const fieldIndex = this.newForm.findIndex(
          (field) => field.name == "year"
        );

        return this.$store.state.quote.newForm[fieldIndex].value;
      },
    },
    model: {
      get() {
        const fieldIndex = this.newForm.findIndex(
          (field) => field.name == "model"
        );

        return this.$store.state.quote.newForm[fieldIndex].value;
      },
    },
    milage: {
      get() {
        const fieldIndex = this.newForm.findIndex(
          (field) => field.name == "milage"
        );

        return this.$store.state.quote.newForm[fieldIndex].value;
      },
    },
    version: {
      get() {
        const fieldIndex = this.newForm.findIndex(
          (field) => field.name == "version"
        );

        return this.$store.state.quote.newForm[fieldIndex].value;
      },
    },
    email: {
      get() {
        const fieldIndex = this.newForm.findIndex(
          (field) => field.name == "email"
        );

        return this.$store.state.quote.newForm[fieldIndex].value;
      },
    },
    phone: {
      get() {
        const fieldIndex = this.newForm.findIndex(
          (field) => field.name == "phone"
        );

        return this.$store.state.quote.newForm[fieldIndex].value;
      },
    },
  },
};
</script>
